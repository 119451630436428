@charset "UTF-8";

@font-face {
  font-family: "OpenSansCondensed-Light";
  src: url(fonts/OpenSansCondensed-Light.ttf) format("truetype"), url(fonts/OpenSansCondensed-Light.woff), url(fonts/OpenSansCondensed-Light.eot), url(fonts/OpenSansCondensed-Light.otf) format("opentype"), url('/fonts/OpenSansCondensed-Light.woff2') format('woff2'), url("fonts/OpenSansCondensed-Light.svg#OpenSansCondensed-Light") format("svg");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  line-height: 20px;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

img {
  width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: #000;
}

body {
  margin: 0;
  height: auto;
}

strong {
  font-weight: bold;
}

input {
  border-bottom: inherit;
  box-shadow: inherit;
  height: inherit;
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  position: inherit;
  opacity: inherit;
  pointer-events: inherit;
}

button:focus {
  background-color: inherit;
}

.spinner {
  width: 60%;
  margin: 80px auto;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #26a69a;
}

/***********estilos dinámicos para fondos y tipografías***********/
.bg-ds {
  background-color: #00609c;
}

.bg-navandu {
  background-color: #292929;
}

.tp-ds {
  color: #00609c;
}

.tp-navandu {
  color: #292929;
}

.line-ds {
  border-bottom: 2px solid #27709F;
}

.line-navandu {
  border-bottom: 2px solid #292929;
}

/***************estilos para login**************/
.content {
  background: url("assets/img/fondo_login.jpg") no-repeat top center;
  height: 100vh;
  width: 100%;
  position: relative;
}

.content .img-login {
  display: none;
}

.wrapper {
  top: 10;
  width: 100%;
}

.cabecera {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2%;
  padding-top: 1%;
  opacity: .8;
}

.cabecera .logos {
  display: inline-flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
}

.cabecera .logos .logo {
  width: 50%;
  padding: 10px;
  max-height: 7vh;
  margin-left:1em;
}

.cabecera .logos .app {
  width: 50%;
  padding: 5px 10px 10px 25%;
}


.menu {
  display: none;
}

.menu a {
  display: inline-flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 100%;
  padding: 20%;
  margin-right: 30px;
  margin-bottom: 25%;
}

a:hover, .menu a.active {
  color: #ce6b03;
}

.tooltip, .tooltip2, .tooltip3 {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext, .tooltip2 .tooltiptext2, .tooltip3 .tooltiptext3 {
  visibility: hidden;
  /* width: 110px;*/
  text-align: center;
  position: absolute;
  z-index: 1;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  padding: 0 15px;
}

.tooltip .tooltiptext {
  color: #fff;
  font-size: 1.5rem;
  width: 120px;
  text-align: center;
}

.tooltip2 .tooltiptext2 {
  color: #000;
  margin-top: 30px;
  margin-left: -70px;
  background-color: #fff;
  font-size: 1.1rem;
}

.tooltip3 .tooltiptext3 {
  color: #000;
  margin-top: -40px;
  margin-left: 0;
  background-color: rgba(232, 232, 237, 1.00);
  border-radius: 25px;
  font-size: 1.1rem;
}

.tooltip .tooltiptext {
  top: -25px;
  left: -30px;
}

.submenu, .usuario-activo {
  display: none;
}
.procesos:hover>.submenu {
  display: block;
  display: flex;
  flex-direction: column;
  border: 1px solid #00609c;
  background-color: rgba(255, 255, 255, 1.00) !important;
  border-radius: 8px;
  position: absolute;
  z-index: 10;
  top: 85%;
  left:-40%;
  padding: 1%;
  width:100px;
}

.submenu a {
  display: inline-flex;
  flex-flow: row;
  padding: 5px 10px !important;
  background-color: rgba(255, 255, 255, 1.00) !important;
  border-radius: 0;
  margin:0;
}

.submenu a mat-icon {
  margin-right: 6px;
}
.breadcrumb {
  background-color: #fff;
}

.breadcrumb a {
  color: #272727 !important;
}

.burger {
  color: #fff;
  font-size: 26px;
  line-height: 150%;
  display: block;
  cursor: pointer;
  width: 26px;
  margin-left: 2%;
}

#toggle {
  display: none;
}

#toggle:checked+.menu {
  display: block;
  width: 100%;
  margin-left: 15px;
}

.cabecera .app {
  width: 30%;
  padding: 2% 2%;
}

.content .login {
  width: 90%;
  margin: 5%;
  background-color: #fff;
  border-radius: 12px;
  padding: 2%;
}

.content .login .row .mat-form-field {
  width: 100%;
}

.content .login .mat-form-field-infix {
  width: 100% !important;
  border-bottom: 1px solid #ce6b03;
  padding-bottom: 3%;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-size: 1.5rem;
}

.content .login .mat-form-field-infix .mat-form-field-label {
  color: #000;
}

.boton {
  border: 1px solid #ce6b03 !important;
  background-color: #fff;
  color: #000;
  width: 50%;
  padding: 1% 1% 1% 6%;
  font-size: 1.5rem;
  margin: 2% 2% 2% 48%;
  text-align: right;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.boton:hover {
  border: 1px solid #000;
  color: #ce6b03;
}

.content .logo-power {
  width: 50%;
  margin: 2% auto;
}

.mat-icon-button:active {
  background-color: rgba(255, 255, 255, 1.00) !important;
}

.cuerpo .mat-icon {
  height: 30px !important;
  line-height: 30px !important;
  width: 30px !important;
}

.mat-icon-button {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}

/***************estilos para listado documentos**************/
.cuerpo {
  width: 96%;
  margin: 5% auto;
}

.cuerpo h1, .new_user h1 {
  font-size: 1.8rem;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-weight: 800;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cuerpo h2 {
  font-size: 1.2rem;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.buscador {
  background-color: #fff;
  margin: 2% 0;
  padding: 2%;
  -webkit-box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
}

.buscador #filter, .buscador #filter2 {
  width: 85%;
  border: 0;
  border-bottom: 1px solid #ce6b03 !important;
  margin: 2%;
  font-size: 1.2rem;
  font-family: 'OpenSansCondensed-Light', sans-serif;
}

.buscador .focus-visible {
  outline: 0 !important;
  background-color: #f9e0c5;
}

.buscador label {
  color: #ce6b03;
  position: inherit;
  top: inherit;
  font-size: inherit;
  cursor: inherit;
  transition: inherit;
  transform-origin: inherit;
  text-align: inherit;
  transform: inherit;
}

.buscador input {
  box-shadow: none !important;
}

.select-state {
  width: 100%;
  margin-bottom: 5px;
  height: 35px;
  display: inline-flex;
  justify-content: flex-end;
}

.select-state .refrescar {
  color: #ce6b03;
  cursor: pointer;
}

.refrescar:hover, .refrescar:active, .refrescar:focus {
  color: green;
  background-color: none !important;
  fill: transparent !important;
}

.cdk-mouse-focused, .cdk-focused, .mat-icon {
  fill: transparent !important;
  background-color: transparent !important;
}

.select-state select {
  border: 0;
  outline: none;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-size: 1rem;
  padding: 2px 5px;
  width: 75px;
  height: 35px;
  background-color: inherit;
}

.cuerpo .tabla {
  -webkit-box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  font-size: 1rem;
  font-family: 'OpenSansCondensed-Light', sans-serif;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 10px !important;
}

.cuerpo .tabla .mat-header-cell {
  font-size: 1rem;
  padding: 4px;
  text-align: left;
}

.cuerpo .tabla .mat-cell {
  font-size: 1rem;
}

.cuerpo .tabla .mat-column-template {
  width: 32%;
}

.cuerpo .tabla .mat-column-name {
  width: 30%;
}

.cuerpo .tabla .mat-column-user, .cuerpo .tabla .mat-column-documentAddressee {
  width: 32%;
}

.remote::before {
  content: "\f187";
  font-family: 'Material Icons';
}

.biometric::before {
  content: "\e330";
  font-family: 'Material Icons';
}

.analog::before {
  content: "\e616";
  font-family: 'Material Icons';
}

.cuerpo .tabla .mat-column-view {
  width: 4%;
  text-align: left;
}

.cuerpo .tabla .action {
  min-width: 114px;
}

.only-icon {
  color: #136CB5;
}

.only-icon:hover {
  color: #73D5F8;
}

.only-icon-disabled {
  color: #ABABAB;
  margin-right: 14px;
}

.only-icon-disabled:hover {
  color: #ABABAB;
}

.cuerpo .tabla .mat-icon-button {
  color: #1fa756;
}

.cuerpo .tabla .mat-icon-button:focus {
  background-color: inherit;
}

.cuerpo .tabla .mat-icon-button:hover {
  color: #AAECAB;
}

.cuerpo .tabla .mat-button-disabled {
  color: #ABABAB;
}

.cuerpo .tabla .mat-button-disabled:hover {
  color: #ABABAB;
  cursor: auto;
}

.condicion {
  text-align: right;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 8%;
}

.condicion input {
  width: 5% !important;
}

.masmenos {
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  margin-top: -7%;
}

.iconomenos {
  margin-right: 10px;
  cursor: pointer;
}

.iconomas {
  float: right;
  margin-right: 0;
  cursor: pointer;
  margin-top: -8%;
}

.cuerpo .tabla .mat-column-creationDate, .cuerpo .tabla .mat-column-signatureDate, .cuerpo .tabla .mat-column-email, .cuerpo .tabla .mat-column-state, .cuerpo .tabla .mat-column-id {
  display: none;
}

.paginacion {
  background-color: #fff;
  width: 100%;
  color: rgba(0, 0, 0, .54);
  text-align: right;
  font-size: .8rem;
  padding: 10px;
}

.paginacion .items {
  border: 0;
  outline: 0;
  margin-right: 2%;
}

.paginacion button, .paginacion button mat-icon {
  padding: 0;
  margin: -5px;
  border-radius: 0;
}

.paginacion select {
  display: inline;
  width: 40px;
  background-color: inherit;
  display: inline;
  padding: 0;
  margin: 0;
  height: 22px;
}

/***************estilos para ver documento**************/
.cuerpo .documento {
  background-color: #fff;
  width: 96%;
  margin: 5% auto;
  -webkit-box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  font-size: 1.1rem;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  padding: 4%;
}

.cuerpo .documento ul {
  padding-left: 10%;
}

.logo-empresa {
  width: 15%;
  float: right;
}

.volver {
  border: 1px solid #ce6b03;
  background-color: #fff;
  color: #000;
  padding: 2%;
  font-size: 1.5rem;
  margin-bottom: 10%;
  margin-right: 2%;
  float: right;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-weight: 600;
  width: 50%;
  text-align: right;
}

.volver:hover {
  border: 1px solid #000;
  color: #ce6b03;
}

.nuevo {
  color: #fff;
  background-color: #27709F;
  padding: 1.8% 1.5% 1% 1.5%;
  border-radius: 50%;
  margin: 1% 0 1.5% 0;
  float: right;
  -webkit-box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
}

.nuevo:hover {
  background-color: #ce6b03;
}

.switch-button {
  display: inline-block;
  text-align: right;
  padding: 2% 4%;
  width: 100%;
}

.varios-firmantes {
  padding-right: 5px;
  font-size: 1.2rem;
}

.switch-button .switch-button__checkbox {
  display: none;
}

.switch-button .switch-button__label {
  background-color: #A3ECF5;
  width: 2rem;
  height: 1rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
}

.switch-button .switch-button__label:before {
  transition: .2s;
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #fdffff;
  content: '';
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px #00609c;
}

.switch-button .switch-button__checkbox:checked+.switch-button__label {
  background-color: #00609c;
}

.switch-button .switch-button__checkbox:checked+.switch-button__label:before {
  transform: translateX(1rem);
}

.tabla-firmantes {
  font-size: 1.1rem;
  padding-left: 10px;
}

.tabla-firmantes tr th {
  color: rgba(0, 0, 0, .54);
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  padding: 2% 5px;
  font-weight: 300;
}

.tabla-firmantes tr td {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  padding: 1% 5px;
  text-align: left;
}

.tabla-firmantes tr th:nth-child(3), .tabla-firmantes tr td:nth-child(3), .tabla-firmantes tr th:nth-child(4), .tabla-firmantes tr td:nth-child(4), .tabla-firmantes tr th:nth-child(5), .tabla-firmantes tr td:nth-child(5) {
  display: none;
}

/***************estilos para editar documento**************/
.formulario {
  padding: 2%;
}

.flex-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-row .dato {
  border: 0;
  margin: 4% 0;
}

.flex-row .phone {
  width: 76%;
}

.flex-row .phone .mat-form-field-wrapper {
  padding-bottom: 1.6em;
}

mat-label, input, select {
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-size: 1rem;
}

.prefix, .tratamiento {
  border: 0;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, .42);
  font-family: 'OpenSansCondensed-Light', sans-serif;
  margin-right: 2%;
  font-size: 1rem;
  color: rgba(0, 0, 0, .42);
}

.prefix {
  display: inline-block;
  width: 20%;
  padding: 0 4px;
  margin-top: -10px;
}

.prefix-section {
  width: 15% !important;
  padding: 0 4px;
  margin-top: -10px;
}

.tratamiento {
  width: 100%;
  margin: 4% 0;
}

.doc-group {
  display: inline-flex;
  flex-flow: row;
}

.num-doc {
  width: 76%;
  margin-right: 4%;
}

.tipo-doc {
  width: 20%;
}

/***************estilos para usuario nuevo**************/
.back_new_user {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 30;
  opacity: 0;
  pointer-events: none;
  transition: all 1s;
}

#back_new_user:target {
  opacity: 1;
  pointer-events: auto;
}

.new_user {
  position: relative;
  width: 96%;
  margin: 5% auto 14% auto;
  border: 1px solid #27709F;
  background-color: #D8E7F7;
  padding: 2%;
}

form input, form select {
  margin: 6% 0;
  border: 0;
  border-bottom: 1px solid #27709F;
  background-color: transparent;
  outline: none;
}

.new_user form input::placeholder {
  color: #27709F;
}

.boton_crear {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.ver-firmante, .ver-detalle, .ver-account {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, .9);
  top: 30%;
  left: 0;
  z-index: 5;
}

.datos-detalle {
  position: fixed;
  margin: 0 2%;
}

.ver-firmante .formulario, .ver-detalle .datos-detalle {
  width: 96%;
  margin: 5% auto;
  background-color: #fff;
  padding: 2%;
  -webkit-box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  font-family: 'OpenSansCondensed-Light', sans-serif;
}
.ver-account .formulario {
  width: 96%;
  margin: 5% auto;
  background-color: #fff;
  /* padding: 2%; */
  -webkit-box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  box-shadow: 0px 0px 24px rgba(5, 40, 55, 0.06);
  font-family: 'OpenSansCondensed-Light', sans-serif;
}

.ver-firmante .formulario h2, .ver-detalle .datos-detalle h2, .ver-account .formulario h2 {
  font-size: 1.2rem;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.tabla-detalle {
  width: 100%;
  font-size: 1.2rem;
  margin-bottom: 10%;
}

.tabla-detalle tr th, .tabla-detalle tr td {
  padding: 2%;
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  text-align: left;
}

.tabla-detalle tr th {
  font-weight: 300;
  color: rgba(0, 0, 0, .5);
}

.form-gen-doc input:not(.expediente):not(.boton), .form-gen-doc select, .info-dest input, .formulario input:not(.boton):not(.boton-up) {
  border-bottom: none !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  height: inherit !important;
}

/***********estilos para procesos************/
.tabla-procesos .mat-column-name, .tabla-procesos .mat-column-description, .tabla-inicio-procesos .mat-column-description {
  display: none;
}

.tabla-procesos td.mat-cell {
  padding-left: 5px !important;
}

.info-dest {
  display: flex;
  flex-flow: column;
}

.info-dest .mat-form-field-infix {
  padding: 5px 0 0 0 !important;
  border-top: 0 !important;
}

.info-dest .mat-form-field-wrapper {
  padding-bottom: 25px !important;
  margin-bottom: -5px !important;
}

.info-dest input {
  border-bottom: 0 !important;
  padding: 0 !important;
  margin: -20px 0 0 0 !important;
}

.info-dest label {
  margin: 0 !important;
  padding: 0 !important;
}

.documento ol {
  padding: 4%;
}

.cienporcien {
  width: 100%;
}

.boton-up {
  border: 1px solid #ce6b03 !important;
  background-color: #fff;
  color: #000;
  width: 50%;
  padding: 1% 1% 1% 6%;
  font-size: 1.5rem;
  margin: 2% 0 2% 5px;
  text-align: right;
  font-family: 'OpenSansCondensed-Light', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.boton-up:hover {
  border: 1px solid #000;
  color: #ce6b03;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  padding: 0 0 4% 0 !important;
}

#mat-input-0 {
  width: 50%;
}

.borrador input {
  background-color: #C8D6F3;
}

.borrador input::placeholder {
  color: #0A64ED;
}

@media only screen and (min-width: 768px) {
  .content {
    height: 190vh;
  }
  .cabecera .logos .logo {
    max-height: 10vh;
  }
  .menu-conexion {
    display:flex;
    flex-flow:row;
    justify-content: center;
    align-items: center;
  }
   .menu-principal {
    flex-basis: 70%;
   }
   .procesos:hover>.submenu {
      left:-30%;
      width:150px;
      margin:0;
  }
   .usuario-activo {
     display:flex;
     flex-flow:row ;
     align-items: center;
     justify-content: center;
     flex-basis:30%;
     font-size: 1rem;
     color: white;
     margin-right: 1.3rem;
   }

   .usuario-activo .label {
     padding-left: 10px;
   }
}

@media only screen and (min-width: 1024px) {
  .content {
    background: none;
    width: 100%;
    position: relative;
  }

  .content .login {
    width: 40%;
    margin: 5% auto;
  }

  .cabecera .logos .logo {
    width: 20%;
    max-height: 15vh;
  }

  .cabecera .logos .app {
    width: 50%;
    padding: 10px 50px 0 38%;

  }

  .menu-principal {
    flex-basis: 85%;
   }

   .usuario-activo {
     flex-basis:15%;

   }


  .menu {
    display: inline-flex;
    flex-direction: row;
    margin-left: 10%;
  }

  .menu a {
    border-radius: 100%;
    padding: 17px;
    cursor: pointer;
  }

  /* .tooltip, .tooptip2 {
    margin-right: 30px;
  } */

  .tooltip:hover .tooltiptext, .tooltip2:hover .tooltiptext2, .tooltip3:hover .tooltiptext3 {
    visibility: visible;
  }

  .burger, #toggle {
    display: none;
  }

  .submenu, .movil {
    display: none;
  }

  .procesos {
    display: block;
  }

  .submenu a mat-icon {
    margin-right: 6px;
  }

  .cabecera .app {
    width: 15%;
    padding: 2% 2%;
  }

  .content .logo-power {
    width: 12%;
    margin: 2% auto;
  }

  /***************estilos para templates**************/
  .cuerpo {
    width: 90%;
    margin: 2% auto;
  }

  .buscador #filter, .buscador #filter2 {
    width: 92%;
    height: 20px;
  }

  .cuerpo .tabla {
    width: 100%;
  }

  .cuerpo .tabla th.mat-header-cell {
    font-size: 1.1rem;
    padding-left: 10px;
  }

  .cuerpo .tabla .mat-cell {
    font-size: 1.1rem;
    text-align: left;
  }

  .cuerpo .tabla .mat-column-description {
    width: 60%;
  }

  .cuerpo .tabla .mat-column-action {
    width: 12%;
    text-align: center;
  }

  .cuerpo .tabla .mat-column-user, .cuerpo .tabla .mat-column-documentAddressee {
    width: 24%;
    padding-left: 10px;
  }

  .cuerpo .tabla .mat-column-template {
    width: 18%;
    padding-left: 10px;
  }

  .cuerpo .tabla .mat-column-view {
    display: table-cell;
    width: 8%;
  }

  .cuerpo .tabla .mat-column-creationDate, .cuerpo .tabla .mat-column-signatureDate {
    display: table-cell;
    width: 10%;
  }

  .cuerpo .tabla .mat-column-id {
    display: table-cell;
    width: 6%;
    padding-left: 10px;
  }

  .cuerpo .tabla .mat-column-email, .cuerpo .tabla .mat-column-phone, .cuerpo .tabla .mat-column-name, .cuerpo .tabla .mat-column-idn {
    display: table-cell;
    text-align: left;
    padding: 4px;
  }

  .mat-paginator-range-label {
    display: none;
  }

  .pendiente, .pending, .asignado {
    text-transform: capitalize;
    color: orange;
  }

  .firmado, .signed {
    text-transform: capitalize;
    color: green;
  }

  .rechazado, .rejected, .error, .expired {
    text-transform: capitalize;
    color: red;
  }

  .pending, .signed, .rejected, .firmado, .rechazado, .expirado, .pendiente, .asignado{
    text-transform: capitalize;
  }

  /***************estilos para ver documento**************/
  .cuerpo .documento {
    width: 80%;
    margin: 2% auto;
  }

  .boton {
    width: 19%;
    padding: 1%;
    margin: 2% 0 2% 81%;
  }

  .boton-fin-proceso {
    margin: 0 2% 0 15%;
  }

  .volver {
    margin: 0 10% 2% 75%;
    width: 15%;
    padding: 15px 1%;
  }

  .enviar2 {
    text-align: right;
  }

  .volver2 {
    margin: 0 0 2% 2% !important;
    width: 25% !important;
  }

  .flex-row {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .flex-row .dato {
    width: 46%;
    margin: 0 2%;
    padding: 1%;
  }

  .flex-row .dato mat-form-field, .flex-row .dato input {
    width: 100% !important;
  }

  .flex-row mat-form-field:first-of-type {
    width: 48%;
    margin-right: 2%;
  }

  .flex-row mat-form-field:last-of-type {
    width: 46%;
    margin-right: 0;
  }

  .expediente {
    margin-bottom: 4%;
  }

  .flex-row .phone .mat-form-field-wrapper {
    padding-bottom: 1.9em;
  }

  .flex-row .prefix-section .mat-form-field-wrapper {
    padding-bottom: 1.9em;
  }

  .prefix {
    width: 15%;
    padding-bottom: 1%;
  }

  .tratamiento {
    width: 27%;
    margin-right: 4%;
    margin-top: 2%;
  }

  .doc-group {
    margin-left: 4%;
    width: 46%;
  }

  .num-doc {
    width: 82% !important;
  }

  .tipo-doc {
    width: 15% !important;
  }

  .phone-group {
    width: 48%;
    margin-right: 2%;
    padding: 0 !important;
    margin-top: -1%;
  }

  .phone {
    width: 80% !important;
    margin-left: 2%;
    margin-right: 0;
  }

  .enviar {
    width: 80%;
    margin: 0 auto;
  }

  .condicion mat-label {
    margin: 0;
  }

  .condicion input {
    width: 5%;
  }

  .masmenos {
    margin-top: 0;
  }

  .iconomas {
    margin-top: -3%;
    margin-right: 10px;
  }

  .tabla-firmantes tr th:nth-child(3), .tabla-firmantes tr td:nth-child(3), .tabla-firmantes tr th:nth-child(4), .tabla-firmantes tr td:nth-child(4), .tabla-firmantes tr th:nth-child(5), .tabla-firmantes tr td:nth-child(5) {
    display: table-cell;
  }

  /***************estilos para nuevo usuario**************/
  .form-gen-doc input:not(.expediente):not(.boton):not(#filter2), .form-gen-doc select, .info-dest input {
    border-bottom: none !important;
    box-shadow: none !important;
    padding: 0;
    margin: 0;
    height: inherit !important;
  }

  form select {
    display: inline-block;
  }

  .flex-row input:last-of-type {
    margin-left: 2%;
  }

  .new_user {
    width: 70%;
    margin: 5% auto 8% auto;
  }

  .boton_crear {
    width: 12%;
    right: 13%;
    padding: 1%;
  }

  .ver-firmante, .ver-account {
    top: 47%;
  }

  .ver-firmante .formulario, .ver-detalle .datos-detalle, .ver-account .formulario {
    width: 60%;
    height: auto;
  }

  .ver-detalle {
    top: 35%;
  }

  .ver-detalle .datos-detalle {
    margin: -2% 20% 5% 20%;
  }

  .datos-detalle .volver2 {
    margin: 0 0 2% 75% !important;
  }

  .clickable {
    cursor: pointer;
  }

  /***********estilos para procesos************/
  .tabla-procesos .mat-column-name, .tabla-procesos .mat-column-description, .tabla-inicio-procesos .mat-column-description {
    display: table-cell;
  }

  .tabla-procesos .mat-column-description, .tabla-inicio-procesos .mat-column-description {
    width: 30% !important;
  }

  .info-dest {
    display: inline-flex;
    flex-flow: row;
  }

  .info-dest .mat-form-field:nth-of-type(1) {
    flex-basis: 33%;
  }

  .info-dest .mat-form-field:nth-of-type(2) {
    margin-right: 2%;
    flex-basis: 25%;
  }

  .tabla-plantillas-firmar, .tabla-documentos {
    width: 80% !important;
    margin: 0 auto;
  }

  .tabla-documentos th:nth-of-type(2), .tabla-documentos td:nth-of-type(2) {
    width: 10%;
    padding-left: 5px;
  }

  .tabla-documentos .mat-column-action {
    text-align: left !important;
  }

  .cienporcien {
    width: 91%;
    margin: 0 auto;
  }

  .boton-up {
    width: 22%;
    margin: 2% 0 1% 5px;
    padding: 5px 1% 5px 6%;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    padding: 0 !important;
  }

  #mat-input-0 {
    width: 100%;
  }

  .enviar .boton-send {
    margin: 2% 4% 4% 18% !important;
  }

  .mat-checkbox-label {
    white-space: normal !important;
  }


  /***********estilos para snackbar************/

  .red-snackbar {
    background: #f32121;
  }
}
